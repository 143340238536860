<template>
  <div>
    <p class="text-promy-gray-700 font-arial font-bold text-lg sm:text-sm py-6">
      {{ risque.name }}
    </p>
    <p
      v-for="(definition, index) in risque.definitions"
      :key="index"
      class="text-promy-gray-600 font-arial sm:text-sm"
    >
      {{ definition }}
    </p>
    <div v-for="(sub, idx) in risque.sub_title" :key="'sub' + idx" class="py-6">
      <p
        v-if="sub.value != 'Non'"
        class="text-promy-gray-600 font-arial font-bold sm:text-sm py-4"
      >
        {{ sub.descr }}
      </p>

      <div
        v-if="sub.value != 'Non'"
        class="w-full flex md:flex-col justify-start items-start"
      >
        <div
          class="w-2/5 md:w-full h-64 border-2 flex justify-center items-center"
        >
          <Map
            v-if="active"
            :id_map="`map_can_${idx}`"
            :layer="sub.layer"
            :rayon="sub.rayon"
            ref="map"
          />
        </div>
        <img
          :src="`https://mapsref.brgm.fr/wxs/georisques/risques?version=1.1.1&service=WMS&request=GetLegendGraphic&format=image/png&style=default&layer=${sub.layer}`"
          class="ml-6 md:ml-0 md:mt-4"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import Map from './map.vue'
import risques from '@/mixins/risques'
import helpers from '@/mixins/helpers'

export default {
  mixins: [risques, helpers],

  props: {
    risque: {
      type: Object,
      require: true,
    },
    active: {
      type: Boolean,
      require: true,
    },
  },

  components: {
    Map,
  },

  created() {
    this.risque.sub_title = []
    this.setDetailRisque()

    this.risque.sub_title.forEach((sub) => {
      axios
        .get(
          `https://mapsref.brgm.fr/wxs/georisques/rapport?X=${this.lat}&Y=${this.long}&rayon=${sub.rayon}&service=wfs&version=1.0.0&request=getfeature&typename=${sub.layer}&propertyname=${sub.properties}&resultType=results`,
        )
        .then(
          (response) => {
            let data_json = this.xmlToJson(response.data)
            sub.value = data_json['wfs:FeatureCollection']['gml:boundedBy'][
              'gml:null'
            ]
              ? 'Non'
              : 'Oui'
          },
          (error) => {
            console.log(error)
          },
        )
    })
  },

  methods: {
    setDetailRisque() {
      this.risques.definitions_risques.CAN.alea.forEach((can) => {
        this.setObj('alea', can)
      })
    },

    setObj(key, obj) {
      let alea_obj = {
        key: key,
        id: obj.id,
        label: obj.label,
        descr: obj.descr,
        properties: obj.properties,
        layer: obj.layer[0],
        value: '',
        rayon: obj.rayon,
        displayRayon: true,
      }
      this.risque.sub_title.push(alea_obj)
    },

    setDescriptions(obj) {
      let descrs = []
      descrs.push(obj.descr)
      for (let i = 0; i < 5; i++) {
        if (obj['descr' + i]) descrs.push(obj['descr' + i])
      }
      return descrs
    },
  },

  computed: {
    ...mapGetters({
      risques: 'address/risques',
      long: 'address/long',
      lat: 'address/lat',
    }),
  },
}
</script>

<style></style>
